input[type='datetime-local'] {
    border-radius: 5px;
    background-color: #242424;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    color-scheme: dark;
    border: none;
    font-size: 15px;
    padding: 10px 10px;
}