.create-roblox-ban-popup-container {
    display: flex;
    flex-direction: column;
    width: 550px;
    background-color: #303030;
    border: 1px solid #706f6f;
    border-radius: 5px;
    animation: fadeIn 0.2s;
} .create-roblox-ban-popup-container h2 {
    background-color: #222222;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 20px;
    border-bottom: 1px solid #706f6f;
    padding: 5px 10px 5px 10px;
}

.create-roblox-ban-popup-header {
    color: #f0be48;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    background-color: #222222;
    font-weight: 500;
    border-bottom: 1px solid #706f6f;
}

.create-roblox-ban-popup-content {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 10px;
    box-sizing: border-box;
    padding-right: 10px;
}

.create-roblox-ban-popup-grouping {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
} .create-roblox-ban-popup-grouping .textbox {
    margin: 0;
} .create-roblox-ban-popup-grouping span {
    font-size: 18px;
} .create-roblox-ban-popup-grouping .textarea {
    margin: 0;
    resize: vertical;
}

.create-roblox-ban-buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .create-roblox-ban-popup-container {
        width: 85%
    }
}