.manage-roblox-bans-page {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    animation: fadeIn 0.2s;
    width: 100%;
    height: calc(100vh - 100px);
}

.manage-roblox-bans-container {
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.2s;
    width: 100%;
}

.manage-roblox-bans-container-header-row {
    background-color: #222222;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #706f6f;
    padding-top: 10px;
    padding-bottom: 10px;
} .manage-roblox-bans-container-header-row h1 {
    margin: 0;
    font-size: 20px;
    text-align: center;
} .manage-roblox-bans-container-header-notice {
    background-color: #222222;
    border-left: 1px solid #706f6f;
    border-right: 1px solid #706f6f;
    border-bottom: 1px solid #706f6f;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 500;
    color: #f0be48;
}

.manage-roblox-bans-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-color: #303030;
    border-left: 1px solid #706f6f;
    border-right: 1px solid #706f6f;
    width: 100%;
    font-size: 20px;
    border-top: none;
}

.manage-roblox-bans-table-container {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    height: fit-content;
    max-height: 59.5vh;
}

.manage-roblox-bans-table-bottom {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    background-color: #222222;
    border: 1px solid #706f6f;
    width: 100%;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
} .manage-roblox-bans-table-bottom ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.2s ease-in-out;
    justify-content: center;
    margin: 0;
    gap: 10px;
    padding: 0;
    font-size: 20px;
    list-style: none;
} .manage-roblox-bans-table-bottom ul li {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.filter-textbox {
    background-color: #303030;
    width: 99%;
    border: 1px solid #706f6f;
    text-align: center;
}