.nav-container {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    z-index: 999;
    background-color: #303030;
    border-bottom: 1px solid #706f6f;
}

.nav-items {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    gap: 15px;
}

.text-column {
    margin-top: -5px;
}

.nav-links {
    height: 20px;
    cursor: pointer;
    width: 20px;
    gap: 20px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
    justify-content: end;
}

.nav-links i:hover {
    transition: all 0.2s ease-in-out;
    color: #66b5e3;
}

.nav-container img {
    height: 55px;
}