body {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #3c3c3c;
  height: fit-content;
  margin: 0;
  color: #fff;
}

a {
  color: #42b9f5;
}

#root {
  height: fit-content
}

.app {
  height: fit-content;
  margin: 0;
}

p {
  margin: 0;
}

.loader {
  margin: auto;
  animation: spin 1.2s linear infinite;
}

.hljs {
  border-radius: 5px;
  height: fit-content;
}

h2 {
  margin: 0;
}

.fade-in {
  animation: fadeIn 0.2s;
}

.opacity-in {
  animation: opacityIn 0.2s;
}

.fade-up {
  animation: fadeUp 0.2s;
}

.size-up {
  animation: sizeUp 0.2s;
}

@keyframes color {
  0% { color: var(--startColor); }
  100% { color: var(--endColor); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  0% { transform: translateY(100px); opacity: 0; }
  100% { transform: 0; opacity: 1; }
}

@keyframes opacityIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeUp {
  0% { transform: translateY(-100px); opacity: 0; }
  100% { transform: 0; opacity: 1; }
}

@keyframes sizeUp {
  0% { transform: scale(0.9); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

.dev-notice-container {
  z-index: 999;
  padding: 15px 15px;
  background-color: #f0be48;
  font-size: larger;
  display: flex;
  justify-content: center;
  align-items: center;
}