.apply-modify-user-popup-container {
    display: flex;
    flex-direction: column;
    width: 550px;
    background-color: #303030;
    border: 1px solid #706f6f;
    border-radius: 5px;
    font-size: 17px;
    animation: fadeIn 0.2s;
} .apply-modify-user-popup-container h2 {
    background-color: #222222;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 20px;
    border-bottom: 1px solid #706f6f;
    padding: 5px 10px 5px 10px;
}

.apply-modify-user-popup-header {
    color: #f0be48;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    background-color: #222222;
    font-weight: 500;
    border-bottom: 1px solid #706f6f;
}

.apply-modify-user-popup-content {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 15px
}

.apply-modify-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: #222222;
    padding: 8px 5px 8px 5px;
    width: 93%;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #706f6f;
} .apply-modify-table span {
    background-color: #303030;
    line-height: 1.75;
    width: 100%;
    padding-left: 3px;
    word-break: break-all;
    padding-right: 3px;
    border-radius: 5px;
    border: 1px solid #706f6f;
} .apply-modify-table p {
    box-sizing: border-box;
    width: 100%;
}

.apply-modify-buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .apply-modify-user-popup-container {
        width: 85%
    }
}