.embed-page {
    width: 100%;
    display: flex;
    gap: 8px;
    overflow: visible;
    flex-direction: row;
    transition: all 0.2s ease-in-out;
    animation: fadeIn 0.2s;
    margin-bottom: auto;
    margin-bottom:25px;
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
} .embed-page .editor {
    border-radius: 5px;
    background-color: #303030;
    padding: 20px 20px;
    width: 100%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: fit-content;
} .embed-page .preview {
    border-radius: 5px;
    background-color: #303030;
    padding: 20px 20px;
    width: 100%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: fit-content;
}

.embed-page-vertical-grouping {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
} .embed-page-vertical-grouping .requiredTitle {
    text-transform: uppercase;
    font-weight: bold;
    color: #ed5555
} .embed-page-vertical-grouping .title {
    text-transform: uppercase;
    font-weight: bold;
    color: #7289da
} .embed-page-vertical-grouping .textbox {
    width: 100%;
} .embed-page-vertical-grouping .textarea {
    width: 100%;
} .embed-page-vertical-grouping em {
    margin-left: 5px;
    font-weight: 400;
    color: #99aab5;
}

.embed-page-horizontal-grouping {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.fields {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    box-sizing: border-box;
} .field {
    background-color: #3c3c3c;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #706f6f;
} .field-editor {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.btns-embed {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    box-sizing: border-box;
} .btn-embed {
    background-color: #3c3c3c;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #706f6f;
} .btn-editor-embed {
    display: flex;
    flex-direction: column;
    gap: 15px;
} .code-editor {
    border-radius: 5px;
    background-color: #242424;
    color: #fff;
    border: none;
    font-size: 15px;
    margin: 0;
    padding: 10px 10px;
}

.embed {
    width: 100%;
    height: fit-content;
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 0px;
} .embed-color {
    width: 5px;
    height: 100;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #151515;
} .embed-content {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px 10px;
    word-wrap: break-word;
    word-break: break-all;
    background-color: #3f3e3e;
    width: 100%;
    height: 100;
} .embed-content .footer {
    margin-top: 10px;
} .embed-content .bodyTitle {
    font-weight: bold;
} .embed-content img {
    border-radius: 20px;
    padding: 10px 10px;
    height: 100px;
    width: 100px;
} .embed-action-row {
    display: flex;
    flex-direction: row;
    margin-top: -8px;
    gap: 6px;
} .embed-action-row .action-row-btn {
    min-width: 30px;
    border-radius: 3px;
    width: fit-content;
    padding: 8px 25px;
}