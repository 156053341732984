.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    background-color: #303030;
    border: 1px solid #706f6f;
    border-radius: 5px;
} .loader-container i {
    font-size: 100px;
} .loader-container span {
    margin-bottom: 45px;
    font-size: 30px;
    font-weight: 500;
}