.manage-users-hidden {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    height: 0;
    opacity: 0;
    width: 100%;
    margin-top: 55vh;
}

.manage-users-page {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    flex-direction: column;
    animation: fadeIn 0.25s;
    width: 100%;
}

.manage-users-container-hidden {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: start;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    height: 0;
    opacity: 0;
    margin-top: -85vh;
}

.manage-users-container {
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
    justify-content: start;
    flex-direction: column;
    gap: 0px;
} 

.manage-users-container-header {
    background-color: #222222;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #706f6f;
    padding-top: 10px;
    padding-bottom: 10px;
} .manage-users-container-header h1 {
    margin: 0;
    font-size: 20px;
    text-align: center;
} .manage-users-container-header-notice {
    background-color: #222222;
    border-left: 1px solid #706f6f;
    border-right: 1px solid #706f6f;
    border-bottom: 1px solid #706f6f;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 500;
    color: #f0be48;
}

.manage-users-table {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
    margin-bottom: 10px;
    height: fit-content;
    border-left: 1px solid #706f6f;
    border-right: 1px solid #706f6f;
    border-bottom: 1px solid #706f6f;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #303030;
}

.manage-users-table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 10px 15px 10px 15px;
    box-sizing: border-box;
    font-size: 20px;
    border-bottom: 1px solid #706f6f;
} .manage-users-table-row:last-child {
    border: none;
} .manage-users-table-row img {
    border-radius: 500px;
    background-color: #222222;
    width: 70px;
    height: 70px;
}

.manage-users-table-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.manage-users-table-row-info {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    justify-content: center;
}

.manage-users-table-row-edit-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
} .manage-users-table-row-edit-info {
    display: flex;
    gap: 5px;
    align-items: start;
} .manage-users-table-row-edit-grouping {
    display: flex;
    flex-direction: column;
    gap: 5px;
}